import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Label, Row, Col, Button, Modal, ModalBody, CustomInput } from 'reactstrap';
import PageNav from '../../components/header/page-navbar.jsx';
import { ApiUrl, ApiKey, WebUrl, BETTING_POOLS, POOL_SHORT_CODE } from '../../util/Constant.js';
import BettingStateModal from './BettingStateModal.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Controller, useForm } from "react-hook-form";
import { createFormBody, stringIsNullOrEmpty } from '../../util/Utility.js';
import { formatNumber } from '../../util/Utility.js';

/// <summary>
/// Author: -
/// </summary>
const AdvanceBetting = props => {
    const _location = useLocation();
    const _history = useHistory();
    const _dispatch = useDispatch();
    const _userData = useSelector(state => state.authState.userData);

    const pageBackHandler = () => {
        if (_location.state?.backToTicketList) {
            _history.replace(WebUrl._CHECK_TICKET, { previousData: {..._location.state?.backToTicketList} })
        }
        else if (isBettingState) {
             setModalOpen(true);
        }
    }
    const [defaultText, setDefaultText] = useState("");
    var { t } = useTranslation();
    const keyboard = useRef();

    const { control, setValue, getValues } = useForm();

    const [isModalOpen, setModalOpen] = useState(false);
    const [isBettingState, setBettingState] = useState(true);
    const [currentInput, setCurrentInput] = useState();
    const [toggledType, setToggledType] = useState([]);
    const [betLines, setBetLines] = useState({});
    const [onNumber, setOnNumber] = useState(false);
    const [onPool, setOnPool] = useState(false);
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [pools, setPools] = useState([]);
    const [poolDisplay, setPoolDisplay] = useState();
    const [pendingKey, setPendingKey] = useState();

    const [eatSequence, setEatSequence] = useState([]);
    const [eatSeqModal, setEatSeqModal] = useState(false);
    const [betLayout, setBetLayout] = useState(["B", "S", "A", "C", "D"]);
    const [creditBalance, setCreditBalance] = useState(0);

    const [pasteModal, setPasteModal] = useState(false);
    const pasteInputRef = useRef(null);

    const _BET_LINE_SELECTOR = "input.bet-line";
    const _MAX_LINE_COUNT = 50;

    /// Author: Wong
    useEffect(() => {
        getDefaultText().then(() => {
            if (_location.state?.betId) {
                betStringParser(_location.state.betId);
            }
        });

        initPools();
        getBetSequences();
        loadCredit();
    }, []);

    /// <summary>
    /// Author : Wong
    /// </summary>
    const loadCredit = () => {
        ApiEngine.get(ApiUrl._API_GET_CREDIT)
            .then((response) => {
                setCreditBalance(response[ApiKey._API_SUCCESS_KEY] ? formatNumber(response[ApiKey._API_DATA_KEY].creditLeft) : "-");
            })
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    const getBetSequences = () => {
        ApiEngine.get(ApiUrl._API_GET_EAT_SEQUENCES).then((response) => {
            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw response[ApiKey._API_MESSAGE_KEY];
            }

            let eatSeqData = response.data;
            setEatSequence(eatSeqData);

            ApiEngine.get(ApiUrl._API_GET_EAT_SEQUENCE)
                .then((response) => {
                    if (!response[ApiKey._API_SUCCESS_KEY]) {
                        throw response[ApiKey._API_MESSAGE_KEY];
                    }

                    let userSequence = response.data;

                    eatSeqData = eatSeqData.map((e) => {
                        e.checked = e.sequence == userSequence;
                        return e;
                    })

                    setEatSequence(eatSeqData);
                    formatBetLayout(userSequence);
                }).catch((err) => {
                    _dispatch(showJsonResponseAlert(false, err));
                })
        }).catch((err) => {
            _dispatch(showJsonResponseAlert(false, err));
        })
    }

    /// Author: Wong
    const betStringParser = (betId) => {
        getTicketDetails(betId).then(({ data }) => {
            setValue('remark', data.remark);

            let betString = data.orderstring;
            let betStringArr = betString.split("\n");
            betStringArr.shift();

            let writeIndex = 0;
            betStringArr.forEach((elem, index) => {
                if (!stringIsNullOrEmpty(elem)) {
                    writeIndex++;
                    setValue(`betLine${writeIndex}`, elem);
                }
            });

            let focusBetLine = document.querySelector(`${_BET_LINE_SELECTOR}:nth-child(${writeIndex + 2})`);
            focusBetLine.focus();
        });
    }

    /// Author: Wong
    useEffect(() => {
        let secBetLine = document.querySelector(`${_BET_LINE_SELECTOR}:nth-child(2)`);
        setValue("betLine0", "d1");
        setValue("betLine1", "#");
        secBetLine.focus();
        setCurrentInput(secBetLine);
    }, [defaultText]);

    /// Author: Wong
    useEffect(() => {
        if (currentInput) {
            currentInput.focus();
        }
        setToggledType([]);
        setKeyboardShown(currentInput != null);
        setOnPool(false);
    }, [currentInput]);

    useEffect(() => {
        if (pendingKey && currentInput) {
            enterNumber(pendingKey);
            setPendingKey();
        }
    }, [currentInput, pendingKey])

    /// Author: Wong
    async function getTicketDetails(betId, date) {
        let url = ApiUrl._API_GET_TICKET_DETAILS;

        url += `?betId=${betId}` + `&dateTime=${date}`;

        return await ApiEngine.get(url);
    }

    /// Author: Wong
    async function initPools() {
        setPools(BETTING_POOLS);

        let keyboardPool = {};
        BETTING_POOLS.map((p) => {
            let formattedKey = `{${p.shortCode}}`;
            keyboardPool[formattedKey] = `<img src='/${p.imgSrc}' />`
        })

        keyboardPool = {
            ...keyboardPool,
            '{box}': "BOX",
            '{ibox}': "I-BOX",
            '{bksp}': "<i class='fas fa-backspace'></i>",
            '{nl}': "<i class='fas fa-level-down-alt'></i>",
            '{bet}': t("BET"),
            '{del}': "<i class='fas fa-eraser'></i>"
        }

        setPoolDisplay(keyboardPool);
    }

    /// Author: Wong
    async function getDefaultText() {
        setValue('betLine0', 'd');
    }

    /// Author: Wong
    async function getFullBetStr() {
        let betStrArr = [];
        let inputArr = document.querySelectorAll(_BET_LINE_SELECTOR);
        for (let i = 0; i < inputArr.length; i++) {
            betStrArr.push(inputArr[i].value);
        }

        return betStrArr.join('\n');
    }

    /// Author: Eastnoname
    async function submissionHandler() {
        _dispatch(setBusy());
        let url = ApiUrl._API_MAKE_ORDER_ADVANCED;

        var betItem = {
            str2: await getFullBetStr(),
            remark: getValues('remark'),
            existing_ticket_id: _location.state?.isEdit ? _location.state.betId : ''
        };

        await ApiEngine.post(url, betItem, {
            'Content-Type': ApiKey._API_APPLICATION_JSON
        }).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _history.push(WebUrl._BET_SUCCESSFUL, { receipt: responseJson[ApiKey._API_DATA_KEY], betUrl: WebUrl._BETTING_ADVANCE })
            }
            else {
                _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
            _dispatch(setIdle());
        });
        return;
    }

    /// Author: Wong
    async function onKeypadKeyPress(key) {
        if (!isNaN(parseInt(key)) || key == "*" || key == "#" || key == "{box}" || key == "{ibox}") {
            if (key == "#" && toggledType.length > 0) {
                return;
            }
            enterNumber(key);
        }

        switch (key) {
            case "B":
            case "S":
            case "A":
            case "C":
            case "D":

                // Only allow action if already has at least 3 number in line
                let existingLine = betLines[currentInput.dataset.id];

                if (existingLine) {
                    let curNumber = existingLine.number;
                    if (curNumber.length < 3 || isNaN(curNumber.substr(curNumber.indexOf("*")))) {
                        return;
                    }
                } else {
                    return;
                }

                setOnNumber(false);
                let tempToggledType = toggledType;

                // If toggle new type before pressing any number, continue as normal
                if (!onNumber && toggledType.length > 0) {
                    if (tempToggledType.includes(key)) {
                        tempToggledType = tempToggledType.filter(t => t != key)
                    } else {
                        tempToggledType = [...tempToggledType, key];
                    }
                } else {
                    // Directly toggle new type for better UX
                    tempToggledType = [key];
                }

                setToggledType(tempToggledType);
                keyboard.current.setInput('');
                break;
            case "{bksp}":
                backspace();
                break;
            case "{nl}":
                nextLine();
                break;
            case "{del}":
                deleteLine();
                break;
            case "{bet}":
                submissionHandler();
                break;
            case `{${POOL_SHORT_CODE._TOTO}}`:
            case `{${POOL_SHORT_CODE._MAGNUM}}`:
            case `{${POOL_SHORT_CODE._DAMACAI}}`:
            case `{${POOL_SHORT_CODE._SG}}`:
            case `{${POOL_SHORT_CODE._88}}`:
            case `{${POOL_SHORT_CODE._STC}}`:
            case `{${POOL_SHORT_CODE._CASH_SWEEP}}`:
            case `{${POOL_SHORT_CODE._GD}}`:
            case `{${POOL_SHORT_CODE._NINELOTTO}}`:
                let poolKey = key.replace(/[{ }]/g, '');
                poolHandler(BETTING_POOLS.find(p => p.shortCode == poolKey).poolId);
                setOnPool(true);
                break;
            default:
                break;
        }
    }

    /// Author: Wong
    function poolHandler(key) {
        deleteLine(true);
        let currentId = currentInput.dataset.id;
        let curValue = getValues(`betLine${currentId}`);

        if (!stringIsNullOrEmpty(curValue)) {
            setValue(`betLine${currentId}`, curValue[0] == "#" ? `${currentInput.value}${key}` : `#${key}`);
        } else {
            setValue(`betLine${currentId}`, `#${key}`);
        }
    }

    /// Author: Wong
    function enterNumber(key) {

        if (onPool) {
            nextLine();
            setPendingKey(key);
            return;
        }
        
       
        let currentId = currentInput.dataset.id;
        let tempBetLines = betLines;
        let existingLine = tempBetLines[currentId];
        let currentLine = existingLine ?? {
            number: '',
            betType: '',
            eatType: []
        };

        // First and second line ignore betting format, its for pool numbers
        if (currentInput.dataset.id <= 1) {
            setValue(`betLine${currentId}`, `${getValues(`betLine${currentId}`)}${key}`);
            return;
        }

        // If toggle bet types
        if (key == "{box}" || key == "{ibox}") {
            let betType = key.replace(/[{ }]/g, '').toUpperCase();
            currentLine.betType = currentLine.betType == betType ? "" : betType;
        } else {
            // If enter numbers or symbols
            if (toggledType.length == 0) {
                currentLine.number = currentLine.number ? currentLine.number += key : key;
            } else {
                setOnNumber(true);
                currentLine.number = currentLine.number.indexOf("#") > 0 ? currentLine.number.substr(0, currentLine.number.indexOf("#")) : currentLine.number;

                betLayout.map((b) => {
                    let tempEatType = {};
                    let rawType = b;
                    let existingAmount = currentLine.eatType ? currentLine.eatType.find(l => l.type == rawType) ? currentLine.eatType.find(l => l.type == rawType).amount : 0 : 0;

                    tempEatType.type = rawType;

                    // If current type is selected type in keypad
                    if (toggledType.includes(rawType)) {
                        tempEatType.amount = existingAmount == 0 ? key : existingAmount + key;
                    } else {
                        tempEatType.amount = existingAmount != 0 ? existingAmount : 0;
                    }

                    if (currentLine.eatType.find(l => l.type == rawType)) {
                        currentLine.eatType = currentLine.eatType.filter((l) => {
                            if (l.type == rawType) {
                                l.amount = tempEatType.amount
                            }
                            return l;
                        });
                    } else {
                        currentLine.eatType.push(tempEatType);
                    }
                });

                setBetLines(tempBetLines);
            }
        }

        tempBetLines[currentInput.dataset.id] = currentLine;
        formatBetLine(currentLine);
    }

    /// Author: Wong
    function formatBetLine(lineObj) {
        let betTypeStr = "";

        switch (lineObj.betType) {
            case "BOX":
                betTypeStr = "*";
                break;
            case "IBOX":
                betTypeStr = "**";
                break;
        }

        let currentId = currentInput.dataset.id;
        setValue(`betLine${currentId}`, `${betTypeStr}${lineObj.number}${lineObj.eatType ? lineObj.eatType.map(l => `#${l.amount}`).join("") : ''}`);
    }

    /// Author: Wong
    function backspace() {
        let tempBetLines = betLines;
        let currentId = currentInput.dataset.id;
        let existingLine = tempBetLines[currentId];

        if (existingLine) {
            if (toggledType.length == 0) {
                existingLine.number = existingLine.number.slice(0, -1);
            } else {
                existingLine.eatType = existingLine.eatType.map((e) => {
                    if (toggledType.includes(e.type)) {
                        e.amount = e.amount.length > 1 ? e.amount.slice(0, -1) : 0;
                    }
                    return e;
                });
            }

            tempBetLines[currentInput.dataset.id] = existingLine;
            formatBetLine(existingLine);
        } else {
            setValue(`betLine${currentId}`, getValues(`betLine${currentId}`).slice(0, -1));
        }
    }

    /// Author: Wong
    async function nextLine() {
        setOnPool(false);
        let currentId = currentInput.dataset.id;
        let nextInput = document.querySelector(`input[data-id='${parseInt(currentId) + 1}']`);
        setCurrentInput(nextInput);
    }

    /// Author: Wong
    function deleteLine(forPool = false) {
        let currentId = currentInput.dataset.id;
        if (!forPool) {
            setValue(`betLine${currentId}`, '');
        }
        let tempBetLines = betLines;
        delete tempBetLines[currentId];
        setBetLines(tempBetLines);
        setToggledType([]);
        setOnPool(false);
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    const updateUserEatSeq = () => {
        let selectedEatSeq = eatSequence.find(e => e.checked);

        ApiEngine.post(ApiUrl._API_UPDATE_EAT_SEQUENCE, createFormBody({
            EatSequenceId: selectedEatSeq.id
        })).then((response) => {
            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw new response[ApiKey._API_MESSAGE_KEY];
            }

            formatBetLayout(selectedEatSeq.sequence);
            _dispatch(showJsonResponseAlert(true, response[ApiKey._API_MESSAGE_KEY]));
            setEatSeqModal(false);
        }).catch((err) => {
            _dispatch(showJsonResponseAlert(false, err));
        })
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    const formatBetLayout = (eatSequence) => {
        let formattedLayout = eatSequence.replace("4A", "D").replace(/3/g, "");
        setBetLayout(formattedLayout.split("-"));
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    const triggerPaste = (e) => {
        let lineDatas = [];
        for (var i = 0; i < _MAX_LINE_COUNT; i++) {
            setValue(`betLine${i}`, "");
        }

        let splittedText = e.clipboardData.getData('Text').split('\n').filter(t => t.trim());
        let rowIndex = 0;

        // Check if pasted string has draw date / pool / both (two line starts with hashtag)
        let drawPoolFound = splittedText.length > 1 ? splittedText[0].startsWith("#") && splittedText[1].startsWith("#") : false;
        let poolFound = splittedText.length > 1 ? splittedText[0].startsWith("#") && !splittedText[1].startsWith("#") : false;

        if (!drawPoolFound) {
            rowIndex = 2;
        }

        if (poolFound) {
            rowIndex = 1;
        }

        // Prefill date if not included
        if (rowIndex > 0) {
            getDefaultText();
        }

        splittedText.map((text) => {
            let curLineText = text.trim();
            if (curLineText) {
                setValue(`betLine${rowIndex}`, curLineText);
                rowIndex++;
            }
        });

        for (var i = 0; i < _MAX_LINE_COUNT; i++) {
            lineDatas.push({ number: getValues(`betLine${i}`) });
        }

        setBetLines(lineDatas);
        setPasteModal(false);
    }

    return (
        <>
            <PageNav
                pageTitle={t("ADVANCE_BETTING")}
                pageBackHandler={pageBackHandler}
                headerOption={
                    <div className="credit-wrapper">
                        {_userData ? _userData.username : "-"}
                        <span className="theme-btn-1 btn-xs badge-credit">
                            <img src={require("../../assets/img/theme/chip.svg")} />
                            {creditBalance}
                        </span>
                    </div>
                }
            />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="advanceBetting" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <div className="form-group">
                                <Label className="form-group m-0 unselectable">{t("REMARK")}</Label>
                                <Controller
                                    control={control}
                                    name={'remark'}
                                    render={({ value, onChange }) => (
                                        <Input className="form-control" value={value} onChange={onChange} onClick={() => { setCurrentInput() }} />
                                    )}
                                />
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between mb-2">
                                    <Label className="form-group m-0 unselectable" style={{ lineHeight: 1.8 }}>{t("ORDER_STRING")}</Label>
                                    <div>
                                        <Button style={{ marginRight: '6px' }} className="form-group m-b-0 theme-btn-1 btn-sm" onClick={() => setPasteModal(true)}>{t("PASTE_STRING")}</Button>
                                        <Button className="form-group m-0 theme-btn-1 btn-sm" onClick={() => setEatSeqModal(true)}>{t("EDIT_BET_SEQUENCE")}</Button>
                                    </div>
                                </div>
                                <div className="paper-string-wrapper">
                                    <div className="paper-view">
                                        <form id="betForm">
                                            {
                                                Array.from(Array(_MAX_LINE_COUNT), (e, i) => {
                                                    return <Controller
                                                        key={i}
                                                        defaultValue=""
                                                        control={control}
                                                        name={`betLine${i}`}
                                                        render={({ value, onChange }) => (
                                                            <input
                                                                readOnly
                                                                value={value}
                                                                data-id={i}
                                                                className={`bet-line form-control ${currentInput ? currentInput.dataset.id == i ? 'betline-active' : '' : ''}`}
                                                                onFocus={e => { e.stopPropagation(); setCurrentInput(e.target); }} />
                                                        )} />
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button type="button" onClick={submissionHandler} className="theme-btn-1 btn">{t("SUBMIT")}</Button>
                            </div>
                            <BettingStateModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-brand" isOpen={eatSeqModal} toggle={setEatSeqModal} centered>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("SELECT_BET_SEQUENCE")}</strong>
                    <ul className="ul-date-selection">
                        {
                            eatSequence.map((e) => {
                                return <li key={e.id}>
                                    <CustomInput
                                        type="radio"
                                        id={`checkbox-${e.id}`}
                                        name="userEatSeq"
                                        value={e.id}
                                        label={e.sequence}
                                        checked={e.checked}
                                        onClick={() => setEatSequence([...eatSequence.filter(es => { es.checked = es.id == e.id; return e; })])}
                                        className="custom-control-brand"
                                    />
                                </li>
                            })
                        }
                    </ul>
                    <div className="text-center">
                        <button type="button" className="btn btn-min-width theme-btn-1" onClick={updateUserEatSeq}>{t("SUBMIT")}</button>
                        <button type="button" className="btn btn-min-width theme-btn-2" onClick={() => setEatSeqModal(false)}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal onOpened={() => pasteInputRef.current.focus()} className="modal-brand" isOpen={pasteModal} toggle={setPasteModal} centered>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("PASTE_STRING_DESC")}</strong>
                    <input ref={pasteInputRef} id="txtPasteContainer" type='text' className='form-control mb-3' inputMode='none' onPaste={triggerPaste} />
                    <div className="text-center">
                        <button type="button" className="btn btn-min-width theme-btn-2" onClick={() => setPasteModal(false)}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>
            <Keyboard
                layout={{
                    'default': [
                        `${pools.map(p => `{${p.shortCode}}`).join(" ")}`,
                        `${betLayout.join(" ")} {bksp}`,
                        `1 2 3 {del} 4 5 6 {nl} 7 8 9 {bet} * 0 #`
                    ]
                }}
                display={poolDisplay}
                buttonTheme={
                    toggledType.map((t) => {
                        return {
                            class: `toggled-${t}`,
                            buttons: `${t}`
                        }
                    })
                }
                theme={`keyboard keyboard-advance ${keyboardShown ? "keyboard-shown" : ""}`}
                keyboardRef={r => (keyboard.current = r)}
                onKeyPress={onKeypadKeyPress}
                disableButtonHold
                disableCaretPositioning
            />
        </>
    )
}

export default AdvanceBetting;